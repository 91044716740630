import { useLocation, navigate } from "@reach/router"
import React, { useState, useEffect } from "react"
import "../layout.css"
import styled from "styled-components"
import LogoTransfez from "../../images/transfez-new-logo.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons"
import Seo from "../SEOComponents/Seo"
import axios from "axios"

function UpdateConfirmation() {
  const location = useLocation()
  const { search } = location || {}
  const [acceptOrNot, setAcceptOrNot] = useState("no")
  const [isSubmit, setIsSubmit] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const getToken = search.split("?token=").join("").split("&type=")[0]
  const getType = search.split("?token=").join("").split("&type=")[1]

  const isPhone = getType === "phone"
  const isEmail = getType === "email"
  const isNotAccept = acceptOrNot === "no"
  const isAccept = acceptOrNot === "yes"

  const textDecider = () => {
    if (isPhone) return ` phone number`
    if (isEmail) return ` email`
    return ` phone / email`
  }

  const phoneOrEmail = textDecider()

  const handleSubmit = async ({ approval }) => {
    const payload = {
      token: getToken,
      approval,
    }
    const headers = {
      ["API-KEY"]: "W4V3sWzJU3WQ9QEQ6kZ7uX4xPf2vcHuF",
    }
    return await axios
      .put(
        `${process.env.GATSBY_SGD_ROOT_URL_ENV}/admin/users/update_after_confirmation`,
        payload,
        { headers }
      )
      .then(res => {
        const { status, message } = res?.data || {}
        if (status === 422 || status === 500) {
          setErrorMessage(message)
        } else {
          setIsSubmit(true)
        }
      })
      .catch(err => {
        navigate("/")
        console.log(err.data.message)
      })
  }

  const handleNot = () => {
    setAcceptOrNot("no")
    handleSubmit({ approval: false })
  }
  const handleYes = () => {
    setAcceptOrNot("yes")
    handleSubmit({ approval: true })
  }

  const isThereTokenOrType = Boolean(getToken) || Boolean(getType)

  return (
    <>
      <Seo title="update-confirmation" />
      <Container>
        <ContainerDiv>
          {isSubmit ? (
            isAccept ? (
              <AcceptSubmitScreen />
            ) : (
              <DeclineSubmitScreen />
            )
          ) : null}
          {!isSubmit && (
            <>
              <LogoImg src={LogoTransfez} alt="logo-transfez" />
              <RoundedDiv>
                <Title>Did you request to make the changes?</Title>
                <Title fsize="0.75rem" ffamily="Typefez-reg">
                  Our customer support team received a request to update your
                  {phoneOrEmail}. We need your confirmation to proceed with the
                  changes
                </Title>
                <ButtonDiv>
                  <CustomButton
                    border="1px solid red"
                    onClick={handleNot}
                    // disabled={!isThereTokenOrType}
                    // cursor={!isThereTokenOrType ? "not-allowed" : "pointer"}
                  >
                    <FontAwesomeIcon
                      icon={faTimes}
                      color={"red"}
                      style={{ marginRight: 10 }}
                    ></FontAwesomeIcon>
                    No, it’s not me!
                  </CustomButton>
                  <CustomButton
                    border="1px solid green"
                    onClick={handleYes}
                    // disabled={!isThereTokenOrType}
                    // cursor={!isThereTokenOrType ? "not-allowed" : "pointer"}
                  >
                    <FontAwesomeIcon
                      icon={faCheck}
                      color={"green"}
                      style={{ marginRight: 10 }}
                    ></FontAwesomeIcon>
                    Yes, confirm changes
                  </CustomButton>
                </ButtonDiv>
                {errorMessage && (
                  <Title
                    color="red"
                    ffamily="Typefez-reg"
                    fsize="12px"
                    mtop="20px"
                  >
                    {errorMessage}
                  </Title>
                )}
              </RoundedDiv>
            </>
          )}
        </ContainerDiv>
      </Container>
    </>
  )
}

export default UpdateConfirmation

const AcceptSubmitScreen = () => {
  return (
    <div style={{ maxWidth: 350 }}>
      <FontAwesomeIcon
        size={"5x"}
        icon={faCheck}
        color={"green"}
        className="mr-2 mb-2"
      />
      <Title fsize="1.5rem">
        We will update your data. Thank you for your confirmation!
      </Title>
    </div>
  )
}

const DeclineSubmitScreen = () => {
  return (
    <div style={{ maxWidth: 350 }}>
      <FontAwesomeIcon
        size={"5x"}
        icon={faTimes}
        color={"red"}
        className="mr-2 mb-2"
      />
      <Title fsize="1.5rem">
        We won’t update your data. Thank you for your confirmation!
      </Title>
    </div>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`

const ContainerDiv = styled.div`
  max-width: 500px;
  text-align: center;
  padding: 12px;
`

const RoundedDiv = styled.div`
  border-radius: 20px;
  padding: 1.5rem;
  background-color: #eaeaea;
  /* background-image: linear-gradient(-45deg, #196e97 0%, #48a9b5 100%); */
`
const ButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 16px;
`

const LogoImg = styled.img`
  width: 180px;
  height: 53px;
  margin-bottom: 1.5rem;
`

const Title = styled.h2`
  font-size: ${p => p.fsize || "1.25rem"};
  color: ${p => p.color || "black"};
  text-align: center;
  font-family: ${p => p.ffamily || "Typefez-med"};
  margin-top: ${p => p.mtop};
`

const CustomButton = styled.button`
  border: ${p => p.border || "1px solid black"};
  background-color: transparent;
  font-size: 0.65rem;
  color: black;
  font-family: "Typefez-med";
  width: 100%;
  padding: 4px 4px;
  /* cursor: pointer; */
  cursor: ${p => p.cursor};
`
